// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import * as vars from '@/vars.json'
import axios from 'axios'

const httpAxios = axios.create({
  params: {},
})

export const factura = {
  namespaced: true,
  state: {
    facturaSaleLst: [],
    noteSaleLst: [],
    facturaPurchaseLst: [],
    notePurchaseLst: [],
    regenerateBillingsLst: [],
  },
  mutations: {
    LOAD_FACTURA_SALE_LST: (state, data) => {
      state.facturaSaleLst = data
    },
    LOAD_NOTE_SALE_LST: (state, data) => {
      state.noteSaleLst = data
    },
    LOAD_FACTURA_PURCHASE_LST: (state, data) => {
      state.facturaPurchaseLst = data
    },
    LOAD_NOTE_PURCHASE_LST: (state, data) => {
      state.notePurchaseLst = data
    },
    LOAD_REGENERATE_BILLINGS_LST: (state, data) => {
      state.regenerateBillingsLst = data
    },
  },
  actions: {
    setNoteSale ({ state, rootState }, [noteData, secnotesale]) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.users.user.uid,
          secnotesale: secnotesale,
        }
        httpAxios.post(vars.url + '/addSaleNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setFacturaSale ({ state, rootState }, [facturaData, secfacsale]) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
          secfacsale: secfacsale,
        }
        httpAxios.post(vars.url + '/addSaleFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    sendFacSrir ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/sendFacSrir', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    recreateFacSrir ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/recreateFacSrir', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    sendFacUserMail ({ state, rootState }, [facturaData, aditionalField]) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
          aditionalField: aditionalField,
        }
        httpAxios.post(vars.url + '/sendUserMail', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    payFacturaPurchaes ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const date = new Date()
        const data = {
          date: new Date().toISOString().substr(0, 10),
          month: months[date.getMonth()],
          description: dataFactura.facturaData.descripcion,
          amount: dataFactura.facturaData.total,
          // patient: this.valuePatient.data.data.names + ' ' + this.valuePatient.data.data.lastNames,
          created: new Date().getTime(),
          documentId: dataFactura.facturaData.id,
        }
        const keyEgress = firebase.database().ref().child(`financial/${dataFactura.uidUser}/egress/`).push().key
        const updateEgress = {}
        data.id = keyEgress
        updateEgress[`financial/${dataFactura.uidUser}/egress/${keyEgress}`] = data
        firebase.database().ref().update(updateEgress)
        firebase.database().ref(`facturapurchase/${dataFactura.uidUser}/${dataFactura.facturaData.id}/pendientepago`).set(false).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    payFacturaSale ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        const months = ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE']
        const date = new Date()
        const data = {
          date: new Date().toISOString().substr(0, 10),
          month: months[date.getMonth()],
          description: dataFactura.facturaData.descripcion,
          amount: dataFactura.facturaData.total,
          // patient: this.valuePatient.data.data.names + ' ' + this.valuePatient.data.data.lastNames,
          created: new Date().getTime(),
          documentId: dataFactura.facturaData.id,
        }
        const keyEntry = firebase.database().ref().child(`financial/${dataFactura.uidUser}/entry/`).push().key
        const updateEntry = {}
        data.id = keyEntry
        updateEntry[`financial/${dataFactura.uidUser}/entry/${keyEntry}`] = data
        firebase.database().ref().update(updateEntry)
        firebase.database().ref(`facturasale/${dataFactura.uidUser}/${dataFactura.facturaData.id}/pendientepago`).set(false).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
        firebase.database().ref(`facturasale/${dataFactura.uidUser}/${dataFactura.facturaData.id}/fechapago`).set(
          facturaData.fechapago,
        ).then(res => {
          resolve(true)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulFacturaSale ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/anulSaleFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulNoteSale ({ state, rootState }, noteData) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/anulSaleNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setFacturaPurchase ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/addPurchaseFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setNotePurchase ({ state, rootState }, [noteData, secnotepurchase]) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.users.user.uid,
          secnotepurchase: secnotepurchase,
        }
        httpAxios.post(vars.url + '/addPurchaseNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulFacturaPurchase ({ state, rootState }, facturaData) {
      return new Promise((resolve, reject) => {
        const dataFactura = {
          facturaData: facturaData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/anulPurchaseFactura', dataFactura).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    anulNotePurchase ({ state, rootState }, noteData) {
      return new Promise((resolve, reject) => {
        const dataNote = {
          noteData: noteData,
          uidUser: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/anulPurchaseNote', dataNote).then((response) => {
          if (response.status.toString() === '200') {
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadFacturaSale ({ commit, rootState }) {
      commit('LOAD_REGENERATE_BILLINGS_LST', [])
      if (rootState.users.user) {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
        firebase.database().ref(`facturasale/${rootState.users.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              const arrRegenerateFac = []
              commit('LOAD_FACTURA_SALE_LST', arr.reverse())
              for (let i = 0; i < arr.length; i++) {
                if (!arr[i].estadoProceso && arr[i].estado) {
                  arrRegenerateFac.push(arr[i])
                }
              }
              commit('LOAD_REGENERATE_BILLINGS_LST', arrRegenerateFac)
            } else {
              commit('LOAD_FACTURA_SALE_LST', [])
              commit('LOAD_REGENERATE_BILLINGS_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_SALE_LST', [])
            commit('LOAD_REGENERATE_BILLINGS_LST', [])
          }
        })
      }
    },
    loadFacturaSaleFilterDate ({ commit, rootState }, { firstDay, lastDay }) {
      if (rootState.users.user) {
        firebase.database().ref(`facturasale/${rootState.users.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_FACTURA_SALE_LST', arr.reverse())
            } else {
              commit('LOAD_FACTURA_SALE_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_SALE_LST', [])
          }
        })
      }
    },
    loadNoteSale ({ commit, rootState }) {
      if (rootState.users.user) {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
        firebase.database().ref(`notesale/${rootState.users.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_NOTE_SALE_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_SALE_LST', [])
            }
          } else {
            commit('LOAD_NOTE_SALE_LST', [])
          }
        })
      }
    },
    loadNoteSaleFilter ({ commit, rootState }, { firstDay, lastDay }) {
      if (rootState.users.user) {
        firebase.database().ref(`notesale/${rootState.users.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_NOTE_SALE_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_SALE_LST', [])
            }
          } else {
            commit('LOAD_NOTE_SALE_LST', [])
          }
        })
      }
    },
    loadFacturaPurchse ({ commit, rootState }) {
      if (rootState.users.user) {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
        firebase.database().ref(`facturapurchase/${rootState.users.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_FACTURA_PURCHASE_LST', arr.reverse())
            } else {
              commit('LOAD_FACTURA_PURCHASE_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_PURCHASE_LST', [])
          }
        })
      }
    },
    loadPurchaseFilter ({ commit, rootState }, { firstDay, lastDay }) {
      if (rootState.users.user) {
        firebase.database().ref(`facturapurchase/${rootState.users.user.uid}`).orderByChild('fechaEmision')
          .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_FACTURA_PURCHASE_LST', arr.reverse())
            } else {
              commit('LOAD_FACTURA_PURCHASE_LST', [])
            }
          } else {
            commit('LOAD_FACTURA_PURCHASE_LST', [])
          }
        })
      }
    },
    loadNotePurchse ({ commit, rootState }) {
      if (rootState.users.user) {
        firebase.database().ref(`notepurchase/${rootState.users.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_NOTE_PURCHASE_LST', arr.reverse())
            } else {
              commit('LOAD_NOTE_PURCHASE_LST', [])
            }
          } else {
            commit('LOAD_NOTE_PURCHASE_LST', [])
          }
        })
      }
    },
  },
}
