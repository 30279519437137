// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const treatment = {
  namespaced: true,
  state: {
    lstTreatments: [],
    lstReceiptsId: [],
    lstTreatmentsPrices: [],
  },
  mutations: {
    SET_TREATMENTS_LST: (state, data) => {
      state.lstTreatments = data
    },
    LOAD_RECEIPTS_IDS: (state, data) => {
      state.lstReceiptsId = data
    },
    LOAD_TREATMENTS_LST: (state, data) => {
      state.lstTreatmentsPrices = data
    },
  },
  actions: {
    loadTreatmentsPrices ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        let path = ''
        if (rootState.users.user.selectAccount === 'personal') {
          path = `${rootState.users.user.selectAccount}/treatmentPrices/${rootState.users.user.uid}`
        } else {
          path = `${rootState.users.user.selectAccount}/treatmentPrices`
        }
        firebase.database().ref(path).on('value', (snapshot) => {
          if (snapshot.val()) {
            const res = snapshot.val()
            if (res) {
              // this.processesAddedArray = res
              commit('LOAD_TREATMENTS_LST', res)
            } else {
              commit('LOAD_TREATMENTS_LST', [])
            }
          } else {
            commit('LOAD_TREATMENTS_LST', [])
          }
        })
      })
    },
    async loadTreatmentsLst ({ commit, rootState }, currentPatientId) {
      await firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${currentPatientId}`).once('value', (snapshot) => {
        if (snapshot) {
          let arr = []
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('SET_TREATMENTS_LST', arr)
          } else {
            commit('SET_TREATMENTS_LST', [])
          }
        } else {
          commit('SET_TREATMENTS_LST', [])
        }
      })
    },
    editDctoTreatment ({ rootState }, dsctoData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${dsctoData.patientId}/${dsctoData.key}/descuento`).set(
          dsctoData.descuento,
        ).then((res) => {
          resolve(true)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    editDctoType ({ rootState }, dsctoData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${dsctoData.patientId}/${dsctoData.key}/dsctoType`).set(
          dsctoData.disccountType,
        ).then((res) => {
          resolve(true)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    editDescriptionTreatment ({ rootState }, dsctoData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${dsctoData.patientId}/${dsctoData.key}/description`).set(
          dsctoData.description,
        ).then((res) => {
          resolve(true)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // metodo para guardar o editar
    setTreatment ({ state, rootState }, [patientId, appointments, date, key, receiptIds, dcto, disccountType, desc]) {
      return new Promise((resolve, reject) => {
        let data = null
        if (!dcto) {
          dcto = 0
        }
        if (!disccountType) {
          disccountType = '$'
        }
        if (!desc) {
          desc = ''
        }
        if (receiptIds) {
          data = {
            appoimentsDataArray: appointments,
            treatmentDate: date,
            treatmentKey: key,
            receiptIds: receiptIds,
            descuento: dcto,
            dsctoType: disccountType,
            description: desc,
          }
        } else {
          data = {
            appoimentsDataArray: appointments,
            treatmentDate: date,
            treatmentKey: key,
            descuento: dcto,
            dsctoType: disccountType,
            description: desc,
          }
        }
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${patientId}/${key}`).set(
          data,
        ).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // se guarda en la BD el array de ids de recibos enlazados a un determinado tratamiento
    saveTreatmentReceipt ({ state, rootState }, [tretamentKey, patientId, receiptKeyArray]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${patientId}/${tretamentKey}/receiptIds`).set(receiptKeyArray).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadReceiptsIds ({ commit, rootState }, [currentPatientId, tretamentKey]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${currentPatientId}/${tretamentKey}/receiptIds`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('LOAD_RECEIPTS_IDS', res)
              resolve()
            } else {
              commit('LOAD_RECEIPTS_IDS', [])
              resolve()
            }
          } else {
            commit('LOAD_RECEIPTS_IDS', [])
            resolve()
          }
        })
      })
    },
    deleteTreatment ({ state, rootState }, [patientId, treatmentId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${patientId}/${treatmentId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setPendingProcess ({ state, rootState }, [patientId, pending, treatKey]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${patientId}/${treatKey}/pendingPrecesses`).set(pending).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
}
