// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const countries = {
  namespaced: true,
  state: {
    countries: null,
  },
  mutations: {
    SET_COUNTRIES: (state, data) => {
      state.countries = data
    },
  },
  actions: {
    async setCountriesData ({ dispatch, commit }) {
      await firebase.database().ref('Country').on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          const arr = Object.keys(res).map(cou => {
            return {
              id: cou,
              data: Object.keys(res[cou]).map(city => {
                return { id: city, data: res[cou][city] }
              }),
            }
          })
          commit('SET_COUNTRIES', arr)
        }
      })
    },
  },
  getters: {

  },
}
