// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
// const moment = require('moment')
import axios from 'axios'
import * as vars from '@/vars.json'

const httpAxios = axios.create({
  params: {},
})

export const financial = {
  namespaced: true,
  state: {
    ingresos: [],
    egresos: [],
    totIngresos: 0,
    totEgresos: 0,
    appointmentToCharge: null,
    receiptNumeration: null,
  },
  mutations: {
    SET_TOTAL_INGRESO: (state, data) => {
      state.totIngresos = data
    },
    SET_TOTAL_EGRESO: (state, data) => {
      state.totEgresos = data
    },
    SET_INGRESOS_LST: (state, payload) => {
      state.ingresos = payload
    },
    SET_EGRESOS_LST: (state, payload) => {
      state.egresos = payload
    },
    SET_APPOINTMENT_TO_CHARGE: (state, data) => {
      state.appointmentToCharge = data
    },
    LOAD_RECEIPT_NUMERATION: (state, data) => {
      state.receiptNumeration = data
    },
  },
  actions: {
    setActualNumeration ({ state, rootState }, actualNumeration) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/receipts/${rootState.users.user.uid}/receiptsNumeration`).set(actualNumeration).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadActualNumeration ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/receipts/${rootState.users.user.uid}/receiptsNumeration`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('LOAD_RECEIPT_NUMERATION', res)
              resolve()
            } else {
              commit('LOAD_RECEIPT_NUMERATION', 0)
              resolve()
            }
          } else {
            commit('LOAD_RECEIPT_NUMERATION', 0)
            resolve()
          }
        })
      })
    },
    saveReceipt ({ state, rootState }, [patientId, receiptId, receiptData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/receipts/${rootState.users.user.uid}/${patientId}/${receiptId}`).set(receiptData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadReceipt ({ commit, rootState }, [currentPatientId, receiptId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/receipts/${rootState.users.user.uid}/${currentPatientId}/${receiptId}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            const res = snapshot.val()
            resolve(res)
          } else {
            resolve([])
          }
        })
      })
    },
    deleteReceipt ({ state, rootState }, [patientId, receiptId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/receipts/${rootState.users.user.uid}/${patientId}/${receiptId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadEntryArr ({ commit, rootState }) {
      var date = new Date()
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
      // const dateFrom = moment().subtract(1, 'years').format('YYYY-MM-DD')
      // const dateTo = moment().add(1, 'month').format('YYYY-MM-DD')
      firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/entry`).orderByChild('data/date')
        .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].data.amount)
              res[cou].data.id = cou
              return res[cou].data
            })
            arr.reverse()
            commit('SET_TOTAL_INGRESO', sumEntry)
            commit('SET_INGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_INGRESO', 0)
            commit('SET_INGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_INGRESO', 0)
          commit('SET_INGRESOS_LST', [])
        }
      })
    },
    filterByDateEntry ({ commit, rootState }, { firstDay, lastDay }) {
      firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/entry`).orderByChild('data/date')
        .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].data.amount)
              res[cou].data.id = cou
              return res[cou].data
            })
            arr.reverse()
            commit('SET_TOTAL_INGRESO', sumEntry)
            commit('SET_INGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_INGRESO', 0)
            commit('SET_INGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_INGRESO', 0)
          commit('SET_INGRESOS_LST', [])
        }
      })
    },
    filterByDateEgress ({ commit, rootState }, { firstDay, lastDay }) {
      firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/egress/`)
        .orderByChild('data/date').startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].data.amount)
              res[cou].data.id = cou
              return res[cou].data
            })
            arr.reverse()
            commit('SET_TOTAL_EGRESO', sumEgress)
            commit('SET_EGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_EGRESO', 0)
            commit('SET_EGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_EGRESO', 0)
          commit('SET_EGRESOS_LST', [])
        }
      })
    },
    loadEgressArr ({ commit, rootState }) {
      var date = new Date()
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
      firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/egress/`)
        .orderByChild('data/date').startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].data.amount)
              res[cou].data.id = cou
              return res[cou].data
            })
            arr.reverse()
            commit('SET_TOTAL_EGRESO', sumEgress)
            commit('SET_EGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_EGRESO', 0)
            commit('SET_EGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_EGRESO', 0)
          commit('SET_EGRESOS_LST', [])
        }
      })
    },
    addEntryVal ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/entry/`).push(
          {
            data: entryData,
          }).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    addEgressVal ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/egress/`).push(
          {
            data: egressData,
          }).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    rmEntry ({ rootState }, idEntry) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/entry/${idEntry}`).remove().then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    rmEgress ({ rootState }, idEgress) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/egress/${idEgress}`).remove().then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateEntryVal ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/entry/${entryData.id}`).set(
          {
            data: entryData,
          }).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateEgressVal ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/egress/${egressData.id}`).set(
          {
            data: egressData,
          }).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getEntryByProcess ({ rootState }, idProcess) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/financial/${rootState.users.user.uid}/entry`).once('value', (snapshot) => {
          const arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              Object.keys(res).map(cou => {
                if (res[cou].data.processId === idProcess) {
                  arr.push({ id: cou })
                }
              })
              resolve(arr)
            } else {
              resolve(false)
            }
          } else {
            resolve(false)
          }
        }, error => {
          reject(error)
        })
      })
    },

    clearData ({ commit }) {
      commit('SET_INGRESOS_LST', [])
      commit('SET_EGRESOS_LST', [])
    },
    sendReceip ({ commit }, data) {
      return new Promise((resolve, reject) => {
        httpAxios.post(vars.url + '/sendReceiptPatient', data).then(res => {
          resolve(res.data)
        }, error => {
          reject(error)
        })
      })
    },
  },
  getters: {

  },
}
