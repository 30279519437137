// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
// const moment = require('moment')

export const financialFac = {
  namespaced: true,
  state: {
    ingresos: [],
    egresos: [],
    totIngresos: 0,
    totEgresos: 0,
    appointmentToCharge: null,
    receiptNumeration: null,
  },
  mutations: {
    SET_TOTAL_INGRESO: (state, data) => {
      state.totIngresos = data
    },
    SET_TOTAL_EGRESO: (state, data) => {
      state.totEgresos = data
    },
    SET_INGRESOS_LST: (state, payload) => {
      state.ingresos = payload
    },
    SET_EGRESOS_LST: (state, payload) => {
      state.egresos = payload
    },
  },
  actions: {
    loadEntryArr ({ commit, rootState }, idUser) {
      var date = new Date()
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
      firebase.database().ref(`financial/${idUser}/entry`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            commit('SET_TOTAL_INGRESO', sumEntry)
            commit('SET_INGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_INGRESO', 0)
            commit('SET_INGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_INGRESO', 0)
          commit('SET_INGRESOS_LST', [])
        }
      })
    },
    filterByDateEntry ({ commit, rootState }, { firstDay, lastDay, idUser }) {
      firebase.database().ref(`financial/${idUser}/entry`).orderByChild('date')
        .startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEntry = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEntry = parseFloat(sumEntry) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            commit('SET_TOTAL_INGRESO', sumEntry)
            commit('SET_INGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_INGRESO', 0)
            commit('SET_INGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_INGRESO', 0)
          commit('SET_INGRESOS_LST', [])
        }
      })
    },
    filterByDateEgress ({ commit, rootState }, { firstDay, lastDay, idUser }) {
      firebase.database().ref(`financial/${idUser}/egress/`)
        .orderByChild('date').startAt(firstDay).endAt(lastDay).once('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            commit('SET_TOTAL_EGRESO', sumEgress)
            commit('SET_EGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_EGRESO', 0)
            commit('SET_EGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_EGRESO', 0)
          commit('SET_EGRESOS_LST', [])
        }
      })
    },
    loadEgressArr ({ commit }, idUser) {
      var date = new Date()
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substr(0, 10)
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().substr(0, 10)
      firebase.database().ref(`financial/${idUser}/egress/`)
        .orderByChild('date').startAt(firstDay).endAt(lastDay).on('value', (snapshot) => {
        let sumEgress = 0
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              sumEgress = parseFloat(sumEgress) + parseFloat(res[cou].amount)
              res[cou].id = cou
              return res[cou]
            })
            arr.reverse()
            commit('SET_TOTAL_EGRESO', sumEgress)
            commit('SET_EGRESOS_LST', arr)
          } else {
            commit('SET_TOTAL_EGRESO', 0)
            commit('SET_EGRESOS_LST', [])
          }
        } else {
          commit('SET_TOTAL_EGRESO', 0)
          commit('SET_EGRESOS_LST', [])
        }
      })
    },
    addEntryVal ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.users.user.uid}/entry/`).push(entryData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    addEgressVal ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.users.user.uid}/egress/`).push(egressData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    rmEntry ({ rootState }, idEntry) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.users.user.uid}/entry/${idEntry}`).remove().then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    rmEgress ({ rootState }, idEgress) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.users.user.uid}/egress/${idEgress}`).remove().then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateEntryVal ({ commit, rootState }, entryData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.users.user.uid}/entry/${entryData.id}`).set(entryData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateEgressVal ({ commit, rootState }, egressData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${rootState.users.user.uid}/egress/${egressData.id}`).set(egressData).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getEntryByProcess ({ rootState }, [documentId, idUser]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`financial/${idUser}/entry`).once('value', (snapshot) => {
          const arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              Object.keys(res).map(cou => {
                if (res[cou].documentId === documentId) {
                  arr.push({ id: cou })
                }
              })
              resolve(arr)
            } else {
              resolve(false)
            }
          } else {
            resolve(false)
          }
        }, error => {
          reject(error)
        })
      })
    },

    clearData ({ commit }) {
      commit('SET_INGRESOS_LST', [])
      commit('SET_EGRESOS_LST', [])
    },
  },
  getters: {

  },
}
