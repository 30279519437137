// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import * as functions from '../../../functions.js'

export const users = {
  namespaced: true,
  state: {
    user: null,
    userStatus: null,
    doubts: [],
    cookies: null,
    accounts: [],
  },
  mutations: {
    SET_USER: (state, account) => {
      state.user = account
    },
    SET_USER_STATUS: (state, status) => {
      state.userStatus = status
    },
    SET_USER_DOUBTS: (state, arr) => {
      state.doubts = arr
    },
    SET_USER_COOKIES: (state, data) => {
      state.cookies = data
    },
    SET_USER_ACCOUNTS: (state, data) => {
      state.accounts = data
    },
  },
  actions: {
    loadAccounts ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.users.user.uid}/accounts`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('SET_USER_ACCOUNTS', res)
            } else {
              commit('SET_USER_ACCOUNTS', res)
            }
          } else {
            commit('SET_USER_ACCOUNTS', false)
          }
        })
      })
    },
    setCookies ({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.users.user.uid}/cookies`).set(
          data,
        ).then(() => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    getCookies ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.users.user.uid}/cookies`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('SET_USER_COOKIES', res)
            } else {
              commit('SET_USER_COOKIES', res)
            }
          } else {
            commit('SET_USER_COOKIES', false)
          }
        })
      })
    },
    loadDoubts ({ commit, rootState }) {
      firebase.database().ref(`doubts/${rootState.users.user.uid}`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(dou => {
              return { id: dou, commentary: res[dou].commentary, response: res[dou].response ? (Object.keys(res[dou].response).map(response => { return res[dou].response[response] })) : [] }
            })
            commit('SET_USER_DOUBTS', arr)
          }
        } else {
          commit('SET_USER_DOUBTS', [])
        }
      })
    },
    addDoubts ({ rootState }, data) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/doubts/${rootState.users.user.uid}`).push(
          {
            commentary: data,
          }).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    addResponse ({ rootState }, data) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/doubts/${rootState.users.user.uid}/${data.id}/response`).push(
          {
            commentary: data.commentary,
          }).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    setDataUser ({ commit }, user) {
      commit('SET_USER', user)
    },
    setAccountUser ({ commit, rootState }, user) {
      return new Promise((resolve, reject) => {
        let obj = localStorage.getItem('user')
        if (obj) {
          obj = JSON.parse(obj)
          obj = functions.decrypt(obj)
          if (obj.selectAccount !== user.selectAccount) {
            firebase.database().ref(`users/${rootState.users.user.uid}/selectAccount`).set(
              user.selectAccount).then((res) => {
              const userData = functions.encrypt(user)
              localStorage.setItem('user', JSON.stringify(userData))
              commit('SET_USER', user)
              resolve(user)
            }).catch((error) => {
              reject(error)
            })
          }
        } else {
          resolve(false)
        }
      })
    },
    getUserStatus ({ commit, rootState }) {
      firebase.database().ref(`users/${rootState.users.user.uid}/status`).on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            commit('SET_USER_STATUS', res)
          } else {
            commit('SET_USER_STATUS', false)
          }
        } else {
          commit('SET_USER_STATUS', false)
        }
      })
    },
    cleanStates ({ commit }) {
      commit('SET_USER_STATUS', null)
      commit('SET_USER', null)
    },
    setSesionUid ({ commit, rootState }, [uidSesion, userId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${userId}/uidSesion`).set(
          uidSesion).then((res) => {
          resolve(uidSesion)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    getSesionUid ({ commit, rootState }) {
      firebase.database().ref(`users/${rootState.users.user.uid}/uidSesion`).on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            const uidSesion = localStorage.getItem('uidSesion')
            if (res !== uidSesion) {
              // functions.logOut()
            }
          } else {
            // functions.logOut()
          }
        } else {
          // functions.logOut()
        }
      })
    },
    getUserData ({ commit, rootState }, userId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${userId}`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('SET_USER', res)
              resolve(res)
            }
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {

  },
}
