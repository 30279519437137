// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import axios from 'axios'
import * as vars from '@/vars.json'

const httpAxios = axios.create({
  params: {},
})

export const doctors = {
  namespaced: true,
  state: {
    doctores: [],
    viewProgressBar: false,
    doctorExist: null,
  },
  mutations: {
    SET_DOCTORES_LIST (state, payload) {
      state.doctores = payload
    },
    SET_VIEW_PROGRESSBAR (state, val) {
      state.viewProgressBar = val
    },
    SET_DOCTOR_EXIST (state, val) {
      state.doctorExist = val
    },
  },
  actions: {
    setViewProgressBar ({ commit }, val) {
      commit('SET_VIEW_PROGRESSBAR', val)
    },
    loadDataDoctors ({ commit, rootState }) {
      firebase.database().ref(`/${rootState.users.user.selectAccount}/doctors`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              return { uid: cou, data: res[cou] }
            })
            commit('SET_DOCTORES_LIST', arr)
          } else {
            commit('SET_DOCTORES_LIST', [])
          }
        } else {
          commit('SET_DOCTORES_LIST', [])
        }
      })
    },
    addDoctor ({ rootState }, doctor) {
      return new Promise((resolve, reject) => {
        const url = rootState.users.user.selectAccount
        const body = {
          doctor: doctor,
          url: url,
          uid: rootState.users.user.uid,
        }
        httpAxios.post(vars.url + '/createDoctor', body).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response.data)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
    updateDoctor ({ rootState }, doctor) {
      return new Promise((resolve, reject) => {
        const url = rootState.users.user.selectAccount
        const body = {
          doctor: doctor,
          url: url,
        }
        httpAxios.post(vars.url + '/updateDoctor', body).then((response) => {
          if (response.status.toString() === '200') {
            resolve(response.data)
          } else {
            resolve(false)
          }
        }).catch((error) => {
          reject(error)
        })
      })
    },
  },
  getters: {

  },
}
