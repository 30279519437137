// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const provider = {
  namespaced: true,
  state: {
    lstProviders: [],
  },
  mutations: {
    LOAD_PROVIDERS: (state, data) => {
      state.lstProviders = data
    },
  },
  actions: {
    setProvider ({ state, rootState }, [providerId, providerData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`providers/${rootState.users.user.uid}/${providerId}`).set(providerData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProviders ({ commit, rootState }) {
      if (rootState.users.user) {
        firebase.database().ref(`providers/${rootState.users.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_PROVIDERS', arr)
            } else {
              commit('LOAD_PROVIDERS', [])
            }
          } else {
            commit('LOAD_PROVIDERS', [])
          }
        })
      }
    },
    deleteProvider ({ state, rootState }, providerId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`providers/${rootState.users.user.uid}/${providerId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
