// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import axios from 'axios'
import * as vars from '@/vars.json'

const httpAxios = axios.create({
  params: {},
})

export const proformas = {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    setProforma ({ state, rootState }, [patientId, appointments, date, key, dcto, discountType, desc]) {
      return new Promise((resolve, reject) => {
        if (!dcto) {
          dcto = 0
        }
        if (!discountType) {
          discountType = '$'
        }
        if (!desc) {
          desc = ''
        }
        firebase.database().ref(`${rootState.users.user.selectAccount}/proformas/${rootState.users.user.uid}/${patientId}/${key}`).set({
          appoimentsDataArray: appointments,
          treatmentDate: date,
          treatmentKey: key,
          descuento: dcto,
          dsctoType: discountType,
          description: desc,
        }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    setPendingProcess ({ state, rootState }, [patientId, pending, key]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/proformas/${rootState.users.user.uid}/${patientId}/${key}/pendingPrecesses`).set(pending).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProformas ({ commit, rootState }, patientId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/proformas/${rootState.users.user.uid}/${patientId}`).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              resolve(arr)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadAppointments ({ commit, rootState }, [firstAppointment, patientId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${rootState.users.user.uid}/${patientId}/${firstAppointment}`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            resolve(res)
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProcess ({ commit, rootState }, [processId, patientId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/processes/${rootState.users.user.uid}/${patientId}/${processId}`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            resolve(res)
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    deleteProforma ({ rootState }, [patientId, proformId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/proformas/${rootState.users.user.uid}/${patientId}/${proformId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteAppointment ({ rootState }, [patientId, appointId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${rootState.users.user.uid}/${patientId}/${appointId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    deleteProcess ({ rootState }, [patientId, processId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/processes/${rootState.users.user.uid}/${patientId}/${processId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getImgB64 ({ commit }, url) {
      return new Promise((resolve, reject) => {
        const dataSend = {
          url: url,
        }
        httpAxios.post(vars.url + '/getImgBase64', dataSend).then(res => {
          resolve(res.data)
        }, error => {
          reject(error)
        })
      })
    },
  },
  getters: {
  },
}
