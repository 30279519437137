// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import router from '../../../router'
import store from '../../../store'

export const suscriptionPlan = {
  namespaced: true,
  state: {
    plan: null,
    doctors: null,
    suscription: null,
    initBan: true,
  },
  mutations: {
    SET_PLAN_DATA (state, data) {
      state.plan = data
    },
    SET_DOCTORS_DATA (state, data) {
      state.doctors = data
    },
    SET_SUSCRIPTION_DATA (state, data) {
      state.suscription = data
    },
    SET_SUSCRIPTION_BAN (state, data) {
      state.initBan = data
    },
  },
  actions: {
    loadPlanSuscription ({ commit, rootState }) {
      firebase.database().ref(`users/${rootState.users.user.uid}/accounts`).once('value', (snapAccounts) => {
        const resAccUser = snapAccounts.val()
        const selectAccount = resAccUser.filter(item => {
          return item.name === rootState.users.user.selectAccount
        })
        if (selectAccount[0].name.includes('clinica')) {
          const accountUid = selectAccount[0].name.toString().split('/')[1]
          firebase.database().ref(`users/${accountUid}/accounts`).once('value', (snapshotClinic) => {
            const resAccounts = snapshotClinic.val()
            const selectAccountClinic = resAccounts.filter(item => {
              return item.name === rootState.users.user.selectAccount
            })
            firebase.database().ref(`plans/clinica/${selectAccountClinic[0].plan}`).on('value', (snapshot) => {
              const res = snapshot.val()
              commit('SET_PLAN_DATA', res.patients)
              commit('SET_DOCTORS_DATA', res.doctors)
            })
            firebase.database().ref(`suscriptions/${selectAccountClinic[0].suscription}`).on('value', (snapshot) => {
              const res = snapshot.val()
              const suscription = {
                days: res,
                created: selectAccountClinic[0].suscriptionDate,
              }
              const dateInit = new Date(suscription.created + 'T00:00:00')
              const currentDate = new Date()

              const differenceTime = currentDate.getTime() - dateInit.getTime()
              const differenceDays = differenceTime / (1000 * 3600 * 24)
              const restDays = parseInt(suscription.days) - parseInt(differenceDays)
              if (restDays <= 5) {
                const data = {
                  descripcion: 'Su suscripción expirará en ' + restDays + ' días. Por favor comuníquese con nosotros para verificar su cuenta.',
                }
                store.dispatch('notifications/setNotificationVal', data)
              }
              if (differenceDays > suscription.days || !rootState.users.user.status) {
                commit('SET_SUSCRIPTION_BAN', false)
                router.push('/suscription')
              }
              commit('SET_SUSCRIPTION_DATA', suscription)
            })
          })
        } else {
          firebase.database().ref(`plans/personal/${selectAccount[0].plan}`).on('value', (snapshot) => {
            const res = snapshot.val()
            commit('SET_PLAN_DATA', res.patients)
          })
          firebase.database().ref(`suscriptions/${selectAccount[0].suscription}`).on('value', (snapshot) => {
            const res = snapshot.val()
            const suscription = {
              days: res,
              created: selectAccount[0].suscriptionDate,
            }
            const dateInit = new Date(suscription.created + 'T00:00:00')
            const currentDate = new Date()

            const differenceTime = currentDate.getTime() - dateInit.getTime()
            const differenceDays = differenceTime / (1000 * 3600 * 24)
            const restDays = parseInt(suscription.days) - parseInt(differenceDays)
            if (restDays <= 5) {
              const data = {
                descripcion: 'Su suscripción expirará en ' + restDays + ' días. Por favor comuníquese con nosotros para verificar su cuenta.',
              }
              store.dispatch('notifications/setNotificationVal', data)
            }
            if (rootState.users.user.status === '') {
              rootState.users.user.status = true
            }
            if (differenceDays > suscription.days || !rootState.users.user.status) {
              commit('SET_SUSCRIPTION_BAN', false)
              router.push('/suscription')
            }
            commit('SET_SUSCRIPTION_DATA', suscription)
          })
        }
      })
    },
  },
}
