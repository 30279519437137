// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const questions = {
  namespaced: true,
  state: {
    questionsLst: [],
    globalQuestion: [],
  },
  mutations: {
    SET_QUESTIONS_LIST (state, payload) {
      state.questionsLst = payload
    },
    SET_GLOBAL_QUESTIONS_LIST (state, payload) {
      state.globalQuestion = payload
    },
  },
  actions: {
    loadQuestion ({ commit, rootState }, patientId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/questions/${rootState.users.user.uid}/${patientId}/questions`).once('value', (snapshot) => {
            let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_QUESTIONS_LIST', arr)
              resolve(arr)
            } else {
              commit('SET_QUESTIONS_LIST', [])
              resolve([])
            }
          } else {
            commit('SET_QUESTIONS_LIST', [])
            resolve([])
          }
        })
      })
    },
    loadGlobalQuestion ({ commit, rootState }) {
      firebase.database().ref(`${rootState.users.user.selectAccount}/globalquestions/${rootState.users.user.uid}/`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('SET_GLOBAL_QUESTIONS_LIST', arr)
          } else {
            commit('SET_GLOBAL_QUESTIONS_LIST', [])
          }
        } else {
          commit('SET_GLOBAL_QUESTIONS_LIST', [])
        }
      })
    },
    addQuestionPatient ({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/questions/${rootState.users.user.uid}/${data.patientId}/`).set(
          {
            questions: data.question,
          },
        ).then(res => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    addGlobalQuestion ({ commit, rootState }, question) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/globalquestions/${rootState.users.user.uid}`).push(
          {
            questions: question,
          },
        ).then(res => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    deleteGlobalQuestion ({ rootState }, idQuestion) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/globalquestions/${rootState.users.user.uid}/${idQuestion}`).remove().then(() => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
  },
}
