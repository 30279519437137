// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const appointment = {
  namespaced: true,
  state: {
    appointments: null,
    // endAppointment: null,
  },
  mutations: {
    SET_APPOINTMENT_LIST: (state, data) => {
      state.appointments = data
    },
    // SET_END_APOINTMENT: (state, appointment) => {
    //   state.endAppointment = appointment
    // },
  },
  actions: {
    loadAppointmetsPrint ({ commit, rootState }, currentPatientId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${rootState.users.user.uid}/${currentPatientId}`).once('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                return {
                  id: cou, data: res[cou],
                }
              })
              arr = arr.reverse()
              resolve(arr)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        })
      })
    },
    async loadAppointmentsData ({ commit, rootState }, currentPatientId) {
      await firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${rootState.users.user.uid}/${currentPatientId}`).once('value', (snapshot) => {
        if (snapshot) {
          let arr = []
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              return {
                id: cou, data: res[cou],
              }
            })
          }
          arr = arr.reverse()
          commit('SET_APPOINTMENT_LIST', arr)
          // commit('SET_END_APOINTMENT', arr[0])
        }
      })
    },
    saveAppointment ({ state, rootState }, [currentDrId, currentPatientId, data, appointmentKey]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${currentDrId}/${currentPatientId}/${appointmentKey}`).set(data).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    updateAppointment ({ state, rootState }, [currentDrId, currentPatientId, idAppintment, data]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${currentDrId}/${currentPatientId}/${idAppintment}`).set(data).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    deleteAppointment ({ state, rootState }, [patientId, appointmentId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${rootState.users.user.uid}/${patientId}/${appointmentId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {

  },
}
