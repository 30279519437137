// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const general = {
  namespaced: true,
  state: {
    signovital: null,
    antecedente: null,
    examenfisico: null,
    personalhistory: null,
    familyhistory: null,
  },
  mutations: {
    SET_SIGNOS_VITALES: (state, data) => {
      state.signovital = data
    },
    SET_ANTECEDENTES: (state, data) => {
      state.antecedente = data
    },
    SET_EXAMEN_FISICO: (state, data) => {
      state.examenfisico = data
    },
    SET_PERSONAL_HISTORY: (state, data) => {
      state.personalhistory = data
    },
    SET_FAMIKY_HISTORY: (state, data) => {
      state.familyhistory = data
    },
  },
  actions: {
    getDataAntecedentes ({ commit, rootState }) {
      firebase.database().ref(`/${rootState.users.user.selectAccount}/antecedentes/${rootState.users.user.uid}/`).once('value', (snapshot) => {
        let arr = []
        const arrAntecedentesLst = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
            })
            for (let i = 0; i < arr.length; i++) {
              for (let j = 0; j < arr[i].data.length; j++) {
                arr[i].data[j].idPatient = arr[i].id
                if (!arr[i].data[j].data.data.arrPreguntasDoctor) {
                  arr[i].data[j].data.data.arrPreguntasDoctor = []
                }
                arrAntecedentesLst.push(arr[i].data[j])
              }
            }
          }
        }
        commit('SET_ANTECEDENTES', arrAntecedentesLst)
      })
    },
    getDataPersonalHistory ({ commit }) {
      let arr = []
      firebase.database().ref('personal-history').on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          arr = Object.keys(res).map(cou => {
            return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
          })
          commit('SET_PERSONAL_HISTORY', arr)
        }
      })
    },
    getDataFamilyHistory ({ commit }) {
      return new Promise((resolve, reject) => {
        let arr = []
        firebase.database().ref('family-history').on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            arr = Object.keys(res).map(cou => {
              return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
            })
            commit('SET_FAMIKY_HISTORY', arr)
          }
        })
      })
    },
    clearData ({ commit }) {
      commit('SET_FAMIKY_HISTORY', [])
      commit('SET_PERSONAL_HISTORY', [])
      commit('SET_ANTECEDENTES', [])
    },
  },
  getters: {

  },
}
