<template>
  <div>
    <offline @detected-condition="handleConnectivityChange" />
    <v-snackbar
      v-model="snackbar.show"
      :multi-line="multiLine"
      :color="snackbar.color"
      :timeout="360000"
      top=""
      right=""
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
          dark=''
          icon=''
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-snackbar
      v-model="snackbar.show"
      :timeout="360000"
      top=""
      right=""
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
        text=""
        dark=""
        icon=""
        @click="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar> -->
    <router-view />
  </div>
</template>

<script>
  import offline from 'v-offline'
  export default {
    name: 'App',
    components: {
      offline,
    },
    data: () => ({
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
    }),
    methods: {
      handleConnectivityChange (status) {
        if (status) {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Conectado a la red',
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Por favor verifica tu conexión a Internet. Y recarga la página.',
          }
        }
      },
    },
  }
</script>

<style>
  .v-application--is-ltr .v-expansion-panel-header {
    text-align: left !important;
    padding: 5px 0px 0px 5px !important;
  }
  .v-navigation-drawer .v-list:not(.v-select-list) {
    background: inherit !important;
    /* padding-left: 0px !important; */
  }
</style>
