export const notifications = {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    SET_NOTIFICATIONS_LST: (state, data) => {
      state.notifications = data
    },
  },
  actions: {
    setNotificationVal ({ commit }, data) {
      commit('SET_NOTIFICATIONS_LST', [])
      const arrNot = []
      arrNot.push(data)
      commit('SET_NOTIFICATIONS_LST', arrNot)
    },
  },
}
