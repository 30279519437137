// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const menus = {
  namespaced: true,
  state: {
    lstMenus: [],
  },
  mutations: {
    SET_MENU_LST: (state, data) => {
      state.lstMenus = data
    },
  },
  actions: {
    loadMenus ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        let url = 'menus/doctor'
        if (rootState.users.user.rol) {
          url = `menus/${rootState.users.user.rol}`
        }
        firebase.database().ref(url).on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('SET_MENU_LST', arr)
            } else {
              commit('SET_MENU_LST', [])
            }
          } else {
            commit('SET_MENU_LST', [])
          }
        })
      })
    },
  },
}
