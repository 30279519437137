// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const product = {
  namespaced: true,
  state: {
    lstProducts: [],
    lstProductsActive: [],
    lstCombos: [],
    totalProducts: null,
  },
  mutations: {
    LOAD_PRODUCTS: (state, data) => {
      state.lstProducts = data
    },
    LOAD_PRODUCTS_ACTIVE: (state, data) => {
      state.lstProductsActive = data
    },
    LOAD_COMBOS: (state, data) => {
      state.lstCombos = data
    },
    LOAD_TOTAL_PRODUCTS: (state, data) => {
      state.totalProducts = data
    },
  },
  actions: {
    setProduct ({ state, rootState }, [productId, productData]) {
      return new Promise((resolve, reject) => {
        const totalValue = (parseFloat(productData.quantity) * parseFloat(productData.activeValue)).toFixed(2)
        productData.totalValue = totalValue
        firebase.database().ref(`products/${rootState.users.user.uid}/${productId}`).set(productData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProducts ({ commit, rootState }) {
      if (rootState.users.user) {
        firebase.database().ref(`products/${rootState.users.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_PRODUCTS', arr)
              const arrActive = arr.filter(item => {
                return item.status !== 'INACTIVO'
              })
              let valTotal = 0
              for (let i = 0; i < arrActive.length; i++) {
                let banClaculate = false
                if (arrActive[i].activeValue) {
                  if (arrActive[i].activeValue !== '') {
                    banClaculate = true
                  } else {
                    banClaculate = false
                  }
                } else {
                  banClaculate = false
                }

                if (banClaculate) {
                  if (arrActive[i].quantity) {
                    if (arrActive[i].quantity.toString().trim() !== '') {
                      if (arrActive[i].quantity.toString().split(' ').length > 1) {
                        arrActive[i].quantity = 1
                      }
                      banClaculate = true
                    } else {
                      arrActive[i].quantity = 0
                      banClaculate = true
                    }
                  } else {
                    banClaculate = false
                  }
                }

                if (banClaculate) {
                  valTotal = valTotal + (parseFloat(arrActive[i].activeValue) * parseFloat(arrActive[i].quantity))
                  if (!valTotal) {
                  }
                }
              }
              commit('LOAD_PRODUCTS_ACTIVE', arrActive)
              commit('LOAD_TOTAL_PRODUCTS', valTotal.toFixed(4))
            } else {
              commit('LOAD_PRODUCTS', [])
              commit('LOAD_PRODUCTS_ACTIVE', [])
              commit('LOAD_TOTAL_PRODUCTS', '0.00')
            }
          } else {
            commit('LOAD_PRODUCTS', [])
            commit('LOAD_PRODUCTS_ACTIVE', [])
            commit('LOAD_TOTAL_PRODUCTS', '0.00')
          }
        })
      }
    },
    deleteProduct ({ state, rootState }, productId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`products/${rootState.users.user.uid}/${productId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setCombo ({ state, rootState }, [productId, productData]) {
      return new Promise((resolve, reject) => {
        const totalValue = (parseFloat(productData.quantity) * parseFloat(productData.activeValue)).toFixed(2)
        productData.totalValue = totalValue
        firebase.database().ref(`combos/${rootState.users.user.uid}/${productId}`).set(productData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadCombos ({ commit, rootState }) {
      if (rootState.users.user) {
        firebase.database().ref(`combos/${rootState.users.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            let arr = []
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              commit('LOAD_COMBOS', arr)
            } else {
              commit('LOAD_COMBOS', [])
            }
          } else {
            commit('LOAD_COMBOS', [])
          }
        })
      }
    },
    deleteCombo ({ state, rootState }, productId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`combos/${rootState.users.user.uid}/${productId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
